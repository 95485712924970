<template>
  <b-card-code title="Configurando cuenta">
    <b-card-text>
      <span>Estamos configurando tu cuenta.</span>
    </b-card-text>

    <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Cargando..."
      variant="primary"/>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BSpinner, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BSpinner,
    BCardText
  }
}
</script>
