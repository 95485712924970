<template>
  <b-row class="match-height">
    <b-col md="12">
      <spinner-border />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import SpinnerBorder from './SpinnerBorder.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,

    SpinnerBorder
  },
  data () {
    return {
      userData: []
    }
  },
  mounted () {
    //this.$vs.loading()
    setTimeout(() => {
      //this.$vs.loading.close()
      //this.$router.push('dashboard-analytics')
      this.userData = JSON.parse(localStorage.getItem('userData'))
      this.$router.push(getHomeRouteForLoggedInUser(this.userData))
    }, 4000)
  }
}
</script>
